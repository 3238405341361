import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductCartItem, ProductCartState } from "../../types/types";

const productCartSlice = createSlice({
  name: "productCart",
  initialState: {
    isCartOpen: false,
    selectedCategory: {},
    curryData: [],
    cartData: [],
    totalSum: 0,
    buildCurry: false,
    categoryData: [],
    productData: [],
  } as ProductCartState,
  reducers: {
    setIsCartOpen: (state, action: PayloadAction<boolean>) => {
      state.isCartOpen = action.payload;
    },
    setCurryData: (state, action: PayloadAction<ProductCartItem[]>) => {
      state.curryData = action.payload;
    },
    setCartData: (state, action: PayloadAction<ProductCartItem[]>) => {
      state.cartData = action.payload;
    },
    setTotalSum: (state, action: PayloadAction<number>) => {
      state.totalSum = action.payload;
    },
    setBuildCurry: (state, action: PayloadAction<boolean>) => {
      state.buildCurry = action.payload;
    },
    setCategoryData: (state, action: PayloadAction<any>) => {
      state.categoryData = action.payload;
    },
    setProductData: (state, action: PayloadAction<any>) => {
      state.productData = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<any>) => {
      
      state.selectedCategory = action.payload;
    },
  },
});

export const {
  setIsCartOpen,
  setCurryData,
  setCartData,
  setTotalSum,
  setBuildCurry,
  setCategoryData,
  setProductData,
  setSelectedCategory,
} = productCartSlice.actions;

export default productCartSlice.reducer;
